import _ from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';

import { Header } from '../layout/header';
import { PageTitle } from '../typography/page-title';
import { Container } from './container';

enum ImageClipping {
    None = 'none',
    VerticalBlob = 'vertical-blob',
}

type Properties = {
    children?: React.ReactChild | React.ReactChild[];

    image: React.ReactElement;
    imageClipping?: ImageClipping;

    pageTitle?: string;
    pageText?: string;
};

/**
 * Render the page text (large heading and text).
 */
const renderHeroBody = (pageTitle?: string, pageText?: string, children?: React.ReactChild | React.ReactChild[]): React.ReactElement => {
    return (
        <>
            <Container className={'flex flex-grow items-center'}>
                {pageTitle && (
                    <div className={'md:max-w-4xl py-24 md:py-44'}>
                        {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
                        {pageText && (
                            <span
                                className={
                                    'hidden sm:block max-w-screen-md font-light leading-tight text-3xl md:text-4xl md:leading-tight text-glow-md'
                                }>
                                {pageText}
                            </span>
                        )}
                        {children}
                    </div>
                )}
            </Container>

            {pageText && (
                <div className={'block sm:hidden py-8 bg-lemon'}>
                    <Container className={'px-6 font-light text-xl'}>
                        <div className={'block'}>{pageText}</div>
                    </Container>
                </div>
            )}
        </>
    );
};

/**
 * Render a hero with a cover image.
 */
const renderCoverHero = (
    image: React.ReactElement,
    pageTitle?: string,
    pageText?: string,
    children?: React.ReactChild | React.ReactChild[]
): React.ReactElement => {
    return (
        <>
            <section className={'relative grid overflow-hidden'}>
                <div className={'flex items-center max-h-80-screen -z-10'} style={{ gridArea: '1/1' }}>
                    <div className={'flex-auto'}>{image}</div>
                </div>

                <div className={'flex flex-col'} style={{ gridArea: '1/1' }}>
                    <Header />

                    {renderHeroBody(pageTitle, pageText, children)}
                </div>
            </section>
        </>
    );
};

/**
 * Render a hero with a vertical blob as its clipped image.
 */
const renderVerticalBlobHero = (
    image: React.ReactElement,
    pageTitle?: string,
    pageText?: string,
    children?: React.ReactChild | React.ReactChild[]
): React.ReactElement => {
    const clipId = _.uniqueId('hero-clip-');

    return (
        <section className={'relative overflow-hidden'}>
            <svg width={0} height={0}>
                <clipPath id={clipId} clipPathUnits="objectBoundingBox">
                    <path d="M0.846,0.143 C0.907,0.213,0.934,0.305,0.96,0.39 C0.985,0.474,1,0.552,1,0.635 S0.993,0.807,0.945,0.88 C0.897,0.953,0.821,1,0.743,1 C0.665,1,0.586,0.946,0.493,0.924 C0.399,0.901,0.29,0.918,0.205,0.879 C0.12,0.841,0.059,0.747,0.029,0.64 C0,0.533,0.003,0.411,0.049,0.31 C0.095,0.209,0.184,0.128,0.282,0.076 C0.381,0.023,0.49,0,0.591,0.012 C0.693,0.025,0.786,0.073,0.846,0.143" />
                </clipPath>
            </svg>
            <div
                className={
                    'absolute -z-10 -right-24 md:-right-24 -bottom-0 md:-bottom-12 xl:-bottom-24 2xl:-bottom-60 w-full sm:w-4/5 md:w-3/5 xl:w-2/5'
                }
                style={{ clipPath: `url(#${clipId})` }}>
                {image}
            </div>

            <Header />

            {renderHeroBody(pageTitle, pageText, children)}
        </section>
    );
};

const Hero = ({ image, imageClipping, pageTitle, pageText, children }: Properties): React.ReactElement => {
    if (imageClipping === ImageClipping.VerticalBlob) {
        return renderVerticalBlobHero(image, pageTitle, pageText, children);
    }

    // TODO: tested with image={<StaticImage src={'https://images.unsplash.com/photo-1469395446868-fb6a048d5ca3'} layout={'fullWidth'} className={'static'} alt={''} />}

    // if (imageClipping === ImageClipping.None) {
    //     return renderCoverHero(image, pageTitle, pageText, children);
    // }

    throw new Error(`Unsupported imageClipping setting: "${imageClipping}"`);
};

Hero.propTypes = {
    children: PropTypes.node,

    image: PropTypes.node.isRequired,
    imageClipping: PropTypes.oneOf([ImageClipping.None, ImageClipping.VerticalBlob]),

    pageTitle: PropTypes.string,
    pageText: PropTypes.string,
};

Hero.defaultProps = {
    imageClipping: ImageClipping.None,

    pageTitle: '',
    pageText: '',
};

export { Hero, ImageClipping };
